<template>
    <div class="w-full h-screen overflow-y-auto">
        <h1 class="font-bold text-white text-2xl mt-10">{{ $t('inviteFriendUpper') }}</h1>
        <div class="flex gap-2 mx-5">
            <div class="mt-5 w-full border border-[#3F71D4] card rounded-xl p-3 flex flex-col justify-between relative overflow-hidden">
                <h4 class="text-[#7F7F7F] font-bold">{{ $t('inviteFriend') }}</h4>
                <div class="flex mt-3 gap-2 items-center">
                    <img :src="store.state.appIcon" alt="coin" class="h-12 w-12 rounded-full">
                    <h4 class="text-[#3A6CCF] text-4xl font-bold">+{{ store.state.appRewards.refReward }}</h4>
                </div>
            </div>
            <div class="mt-5 w-full border border-[#3F71D4] card rounded-xl p-3 flex flex-col justify-between relative overflow-hidden">
                <h4 class="text-[#7F7F7F] font-bold">{{ $t('refClaimReward') }}</h4>
                <div class="flex mt-3 gap-2 items-center">
                    <img :src="store.state.appIcon" alt="coin" class="h-12 w-12 rounded-full">
                    <h4 class="text-[#3A6CCF] text-3xl font-bold">+{{ store.state.appRewards.refClaimReward }}%</h4>
                </div>
            </div>
        </div>
        <div class="w-screen flex mt-5 justify-center px-4 gap-3">
            <div class="rounded-2xl bg-[#3A6CCF] p-3 items-center flex w-full gap-4 mx-2 justify-center" @click="copyRef">
                <img src="../assets/copy.svg" alt="copy" class="h-9 w-9">
                <h4 class="font-semibold text-xl text-white">{{ $t('copyRef') }}</h4>
            </div>
            <div class="bg-[#3A6CCF] rounded-2xl px-4 flex justify-center items-center" @click="shareRef">
                <img src="../assets/share.svg" alt="share" class="h-9 w-9">
            </div>
        </div>
        <div class="flex flex-col justify-start mt-5 items-start px-5 w-full">
            <h1 class="text-white font-bold text-lg">{{ user.friends?.length ?? 0 }} Friends</h1>
            <div class="flex flex-col w-full overflow-y-auto" v-if="user.friends?.length > 0">
                <div class="mt-3 border border-[#3F71D4] bg-[#3F71D4] h-20 bg-opacity-10 rounded-2xl p-3 flex items-center justify-between " v-for="friend in user.friends" :key="friend.name">
                    <div class="flex items-center">
                        <div class="relative w-12 h-12 flex justify-center items-center flex-col">
                            <div class="absolute w-12 h-12 rounded-full border-secondary-yellow border-4"></div>
                            <h4 class="text-secondary-yellow text-xl font-bold">{{ friend.name.charAt(0).toUpperCase() }}</h4>
                        </div>
                        <h4 class="text-white font-bold text-lg ms-3">{{ friend.name }}</h4>
                    </div>
                    <div class="flex items-center gap-2">
                        <h4 class="text-white font-semibold text-xl">{{ friend.totalEarned.toLocaleString() }}</h4>
                        <img :src="store.state.appIcon" alt="appicon" class="h-4 w-4 rounded-full"/>
                    </div>
                </div> 
            </div>

            <div v-else class="w-full justify-center">
                <h4 class="text-gray-500">{{ $t('noFriends') }}</h4>
            </div>
        </div>
        <br>
        <br>
        <br>
        <br>
    </div>
</template>
<script setup>
import { computed } from 'vue';
import store from '@/store';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

const $toast = useToast();

const user = computed(() => {
  return store.state.user;
});

const shareRef = () => {
  const shareLink = `https://t.me/pepefitokenbot/app?startapp=${user.value.telegramId}`;
  const shareText = "Come join to Pepe.fi to earn rewards!";
  window.open(`https://telegram.me/share/url?url=${encodeURIComponent(shareLink)}&text=${encodeURIComponent(shareText)}`, '_blank');
};

const copyRef = () => {
    const el = document.createElement('textarea');
    el.value = `t.me/pepefitokenbot/app?startapp=${user.value.telegramId}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    $toast.success("Ref link has been copied to clipboard!", { position: 'top', duration: 1500 });
}
</script>

<style>
.card {
  background: rgb(62,54,72);
  background: linear-gradient(90deg, rgba(62,54,72,0.5) 0%, rgba(60,62,77,0) 100%);
}
</style>