<template>
  <div class="home h-full">
    <nav class="nav flex justify-between items-center content-center p-2 relative">
      <div class="flex justify-center items-center content-center mt-2" v-on:click="showModal">
        <img :src="userRank?.icon" alt="rank" class="h-12 w-12 rounded-full">
        <h1 class="text-white font-semibold text-xl ms-2">{{ userRank?.name }}</h1>
      </div>
      <TonConnectButton/>
    </nav>
    
    <div class="mt-3 border border-[#3F71D4] bg-black rounded-2xl p-2 flex gap-2 items-center justify-center mx-5 overflow-hidden">
      <img :src="store.state.appIcon" alt="coin" class="relative" style="height: 30px; width: 30px; border-radius: 100%">
      <h4 class="text-white font-bold text-4xl">
        {{ user.balance.toLocaleString() }}
      </h4>
    </div>

    <div class="flex justify-center mt-10">
      <div class="relative rounded-full flex items-center justify-center w-full">
        <div class="relative rounded-full h-64 w-64" @click="tapClaim"></div>
        <img ref="card" :src="userRank?.icon" alt="coin" class="rounded-full transition-transform duration-100 absolute h-60 w-60 pointer-events-none">
      </div>
    </div>
    <div
      v-for="click in clicks"
      :key="click.id"
      class="absolute text-5xl font-bold opacity-0 text-white pointer-events-none"
      :style="{
        top: `${click.y - 42}px`,
        left: `${click.x - 28}px`,
        animation: 'float 1s ease-out'
      }"
      @animationend="handleAnimationEnd(click.id)"
    >
      {{ store.state.tapClaimReward }}
    </div>

    <div class="flex gap-2 items-center justify-center content-center mt-10">
      <div class="rounded-xl bg-slate-500 h-3 w-72 relative overflow-hidden">
        <div class="rounded-2xl bg-[#3F71D4] absolute h-3" :style="{width: (user.tapCount / (store.state.appRewards?.tapLimit ?? 1)) * 100 + '%'}">
        </div>
      </div>
      <h3 class="font-bold text-white text-base">{{ user.tapCount }} / {{ store.state.appRewards?.tapLimit ?? 0 }}</h3>
    </div>

    <div class="mt-10 border border-[#3F71D4] rounded-2xl flex items-center mx-10 relative overflow-hidden">
      <div v-if="user.lastClaimed != null && Date.now() < store.state.nextClaim" class="flex items-center" v-on:click="claimStorage">
        <div class="absolute">
          <svg width="346" height="48" viewBox="0 0 346 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M55.5675 25.2661C27.7096 21.7107 6.91506 29.4824 0 33.8127V48H346V0.993757C341.308 3.15889 328.218 6.19008 313.4 0.993757C294.878 -5.50165 282.283 21.5056 250.424 33.8127C218.565 46.1198 181.52 29.0266 170.407 19.4544C159.293 9.88222 148.921 9.19849 128.176 19.4544C107.43 29.7103 90.3897 29.7103 55.5675 25.2661Z" fill="url(#paint0_linear_37_121)" fill-opacity="0.5"/>
            <defs>
            <linearGradient id="paint0_linear_37_121" x1="173" y1="0" x2="173" y2="48" gradientUnits="userSpaceOnUse">
            <stop stop-color="#9D00FF"/>
            <stop offset="1" stop-color="#9D00FF" stop-opacity="0"/>
            </linearGradient>
            </defs>
          </svg>
        </div>
        <h3 class="text-white font-bold relative m-4">{{ countdown }}</h3>
        <h1 class="text-white font-bold relative text-3xl ms-10">{{ user.storage.toLocaleString() }}</h1>
      </div>
      <div v-else-if="user.lastClaimed && Date.now() > store.state.nextClaim" class="flex justify-center items-center border-[#3F71D4] bg-[#3F71D4] bg-opacity-10 w-full" v-on:click="claimStorage">
        <h3 class="text-white font-bold relative m-4">{{ $t('claimStorage') }}</h3>
      </div>
      <div v-else class="w-full flex justify-center bg-slate-900" v-on:click="startMining">
        <h3 class="text-white font-bold m-4">{{ $t('startMining') }}</h3>
      </div>
    </div>

    <div class="mt-5 mx-5 border border-[#3F71D4] bg-black rounded-2xl p-2 flex items-center justify-between relative overflow-hidden">
        <div class="flex items-center">
            <img :src="currentClaimUpgrade.icon" alt="box" style="height: 50px; width: 50px;" class="rounded-full">
            <div class="flex flex-col items-start ms-3 text-white">
              <h3 class="text-medium">{{ currentClaimUpgrade.name }}</h3>
              <h4 class="text-[#B8B8B8]">{{ $t('storage') }}</h4>
              <h4 class="text-[#B8B8B8] text-xs text-start">
                  {{ currentClaimUpgrade.description }}
              </h4>
            </div>
        </div>
        <button class="bg-[#171717] p-3 text-white rounded-xl" v-on:click="routeToUpgrade">{{ $t('upgrade') }}</button>
    </div>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <dialog id="leaderboard_modal" class="modal modal-bottom sm:modal-middle rounded-t-2xl p-0 text-white">
      <div class="modal-box h-full rounded-t-2xl p-0 bg-slate-900">
        <div class="mt-15">
          <div class="swiper-container mt-5 px-2 swiper-no-swiping sm:swiper-no-swiping">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="rank in store.state.ranks" :key="rank.name">
                <button class="icon-button absolute top-4 right-4" aria-label="Close" @click="closeModal">
                  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.0693 0.000495159C28.2555 0.08988 36.232 8.20359 35.995 18.4788C35.7676 28.3179 27.783 36.0673 17.8513 35.9996C7.69758 35.9318 -0.412937 27.4498 0.0162846 17.2138C0.415718 7.66998 8.3448 -0.0712836 18.0693 0.000495159ZM18.1045 21.8713C18.2507 22.0975 18.3509 22.3074 18.4999 22.4699C19.6115 23.6821 20.6988 24.9172 21.8605 26.0792C22.8517 27.0692 24.3181 27.0773 25.3552 26.2052C26.3586 25.3601 26.5969 23.9164 25.8576 22.7828C25.5529 22.3156 25.1305 21.9242 24.75 21.5097C23.7033 20.3721 22.6513 19.2413 21.5911 18.0955C22.9464 16.6342 24.2558 15.2365 25.5475 13.8226C26.4642 12.8204 26.5319 11.481 25.7533 10.4165C25.0465 9.45226 23.7128 9.07035 22.6215 9.61749C22.134 9.86262 21.7021 10.2621 21.3176 10.6617C20.1992 11.8291 19.1187 13.0317 18.0111 14.2316C16.7722 12.8976 15.5969 11.6192 14.4081 10.3542C13.6864 9.58634 12.8388 9.14619 11.7501 9.46039C9.70964 10.0482 9.06513 12.2543 10.4923 13.8348C11.7745 15.2569 13.0893 16.6491 14.4135 18.0793C14.2632 18.254 14.1386 18.4097 14.0032 18.556C12.8415 19.8155 11.6784 21.0723 10.5166 22.3318C9.44155 23.4992 9.4754 25.0892 10.5938 26.1293C11.6892 27.1478 13.2301 27.0651 14.3268 25.9072C15.5481 24.6179 16.7546 23.3137 18.1045 21.8686V21.8713Z" fill="#4C4E52"/>
                  </svg>
                </button>
                <div class="flex flex-col justify-center items-center">
                  <img :src="rank.icon" :alt="rank.name" style="height: 130px; width: 130px;" class="rounded-full">
                  <h4 class="text-2xl font-bold text-white mt-2">{{ rank.name }}</h4>
                  <div class="flex gap-3 items-center content-center mt-3" v-if="rank.id == userRank?.id">
                    <div class="rounded-xl bg-slate-500 h-6 w-72 relative overflow-hidden">
                      <div class="rounded-2xl bg-[#225dd1] absolute h-6" :style="getProgressStyle(rank.maxRange)"></div>
                    </div>
                    <h4>{{ donePercentage(rank.maxRange) }}%</h4>
                  </div>
                  <div class="flex gap-1 items-center justify-center mt-3" v-else-if="rank.id > userRank?.id">
                    <h4 class="font-semibold text-white">{{ $t('collectMin', { amount: rank.minRange.toLocaleString() }) }}</h4>
                    <img :src="store.state.appIcon" alt="appicon" class="h-4 w-4 rounded-full"/>
                  </div>
                  <div class="flex gap-1 items-center justify-center mt-3" v-else>
                    <h4 class="font-semibold text-white">{{ $t('levelDone') }}</h4>
                  </div>
                  <div class="flex justify-between w-full absolute px-6">
                    <button class="ms-4 bg-[#3F71D4] rounded-full h-11 w-11 bg-opacity-40 flex justify-center items-center" @click="swiper.slidePrev">
                      <svg width="15" height="27" viewBox="0 0 15 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 1L1 13.499L14 26" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </button>
                    <button class="me-4 bg-[#3F71D4] rounded-full h-11 w-11 bg-opacity-40 flex justify-center items-center" @click="swiper.slideNext">
                      <svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.18143 26.1046L14.0904 13.5116L1 1.10529" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="h-[1px] bg-gray-500 mx-4 my-5"></div>
                <h4 class="text-base font-medium text-slate-300">{{ $t('leaderBoard') }}</h4>
                <div class="friends-wrapper flex flex-col w-full py-4 min-h-28 max-h-96">
                  <div class="mt-5 mx-5 border bg-opacity-10 rounded-2xl p-3 flex items-center justify-between relative" v-for="(user, index) in getLeaderBoard(rank)" :key="user.name" :class="user.telegramId == store.state.user.telegramId ? 'border-[#00D98B] bg-[#193019]' : 'border-[#3F71D4] bg-[#3F71D4]'">
                    <div class="flex items-center justify-start">
                      <div class="relative w-12 h-12 flex justify-center items-center flex-col">
                        <div class="absolute w-12 h-12 rounded-full border-secondary-yellow border-4"></div>
                        <h4 class="text-secondary-yellow text-xl font-bold">{{ index + 1 }}</h4>
                      </div>
                      <h4 class="text-white font-bold text-lg ms-3 text-start">{{ user.name }}</h4>
                    </div>
                    <div class="flex items-center gap-2">
                      <h4 class="text-white font-semibold text-xl">{{ user.balance.toLocaleString("tr-TR") }}</h4>
                      <img :src="store.state.appIcon" alt="appicon" class="h-6 w-6 rounded-full"/>
                    </div>
                  </div>
                </div>
                <div class="w-full" v-if="!isUserInTop">
                  <div class="mt-5 mx-5 border bg-opacity-10 rounded-2xl p-3 flex items-center justify-between overflow-hidden border-[#00D98B] bg-[#193019]">
                  <div class="flex items-center">
                      <div class="relative w-12 h-12 flex justify-center items-center flex-col">
                        <div class="absolute w-12 h-12 rounded-full border-secondary-yellow border-4"></div>
                        <h4 class="text-secondary-yellow text-xl font-bold">{{ userPlacement }}</h4>
                      </div>
                      <h4 class="text-white font-bold text-lg ms-3">{{ user.name }}</h4>
                    </div>
                    <div class="flex items-center gap-2">
                      <h4 class="text-white font-semibold text-xl">{{ user.balance.toLocaleString("tr-TR") }}</h4>
                      <img :src="store.state.appIcon" alt="appicon" class="h-6 w-6 rounded-full"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dialog>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import { useVibrate } from '@vueuse/core';
import store from '@/store';
import userService from '@/service/userService';
import mineService from '@/service/mineService';
import route from '@/router/index';
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import { TonConnectButton, useTonConnectUI } from '@townsquarelabs/ui-vue';
import adService from '@/service/adService';

// eslint-disable-next-line
const { vibrate, stop } = useVibrate();
const [tonConnectUi] = useTonConnectUI();

const user = computed(() => {
  return store.state.user;
});

const countdown = computed(() => {
  return store.state.countdown;
});

const userRank = computed(() => {
  return store.state.ranks.find(rank => {
    return user.value.allTimeBalance >= rank.minRange && user.value.allTimeBalance <= rank.maxRange;
  });
});

const swiper = ref(null);
const isFirstSlide = ref(true);
const isLastSlide = ref(false);
const showText = ref(false);
const isTapped = ref(false);
const card = ref(null);
const clicks = ref([]);

const startMining = async () => {
  const userId = store.state.user.telegramId;

  const res = await userService.startMining(userId);

  const claimBox = store.state.claimUpgrades.find(upgrade => upgrade.level == store.state.user.claimLevel);
  const claimFillDurationHour = claimBox.claimFillDurationHour;

  const mineBox = store.state.mineUpgrades.find(upgrade => upgrade.level == store.state.user.mineLevel);
  const minePerSecond = (mineBox.hourlyRate / 60) / 60;

  mineService.startMining(claimFillDurationHour, minePerSecond);

  store.commit('setUser', res.user);
}

const showModal = () => {
  document.getElementById('leaderboard_modal').showModal();
}

const closeModal = () => {
  document.getElementById('leaderboard_modal').close();
}

const getLeaderBoard = (rank) => {  
  const users = store.state.leaderboard.ranksAndUsers.find(leaderboard => {
    return rank.id == leaderboard.rankId;
  }).topUsers;

  return users;
};

const userPlacement = computed(() => {
  return store.state.leaderboard.place;
});

const isUserInTop = computed(() => {
  return store.state.leaderboard.place <= 10;
});

const getProgressStyle = (maxRange) => {
  const progress = (store.state.user.balance / maxRange) * 100;

  return {
    'width': `${progress}%`,
  };
}

const currentClaimUpgrade = computed(() => {
    return store.state.claimUpgrades.find(level => level.level == store.state.user.claimLevel);
});

const tapClaim = async (event) => {
  const tapLimit = store.state.appRewards.tapLimit;

  if(user.value.tapCount + 1 > tapLimit) return;

  const userId = store.state.user.telegramId;

  await userService.tapClaim(userId);

  const balance = store.state.user.balance + store.state.tapClaimReward;
  user.value.tapCount += 1;

  store.commit('setBalance', balance);
  store.commit('setUser', user.value);

  vibrate();

  const rect = card.value.getBoundingClientRect();
  const x = event.clientX - rect.left - rect.width / 2;
  const y = event.clientY - rect.top - rect.height / 2;

  card.value.style.transform = `perspective(300px) rotateX(${-y / 10}deg) rotateY(${x / 10}deg)`;

  setTimeout(() => {
    card.value.style.transform = '';
  }, 150);

  clicks.value.push({ id: Date.now(), x: event.pageX, y: event.pageY });

  isTapped.value = true;
  showText.value = true;

  setTimeout(() => {
    showText.value = false;
  }, 150);
}

const donePercentage = (maxRange) => {
  const percentage = ((store.state.user.balance / maxRange) * 100).toFixed(0);
  
  return percentage > 100 ? 100 : percentage;
};

const claimStorage = async () => {
  if(Date.now() < store.state.nextClaim) return;

  try {
    try {
      await adService.showAd();
    } catch(e){
      console.log(e);
    }

    const res = await userService.claimStorage(store.state.user.telegramId);

    store.commit('setUser', res.user);
    store.commit('setNextClaim', new Date(res.nextClaim));

    let countDownInterval = setInterval(() => {
      const currentTime = new Date().getTime();
      const nextClaimTime = store.state.nextClaim;
      const timeLeft = nextClaimTime - currentTime;

      if (timeLeft < 0) {
        store.commit('setCountdown', '00:00:00');

        clearInterval(countDownInterval);

        return;
      }

      const hours = Math.floor(timeLeft / (1000 * 60 * 60)).toString().padStart(2, '0');
      const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');

      store.commit('setCountdown', `${hours}h ${minutes}m`);
    }, 1000);


    const claimBox = store.state.claimUpgrades.find(upgrade => upgrade.level == store.state.user.claimLevel);
    const claimFillDurationHour = claimBox.claimFillDurationHour;

    const mineBox = store.state.mineUpgrades.find(upgrade => upgrade.level == store.state.user.mineLevel);
    const minePerSecond = (mineBox.hourlyRate / 60) / 60;

    mineService.startMining(claimFillDurationHour, minePerSecond);
  } catch(e) {
    return e;
  }
}

const routeToUpgrade = async () => {
  route.push({ name: 'upgrades' });
}

const handleAnimationEnd = (id) => {
    clicks.value = clicks.value.filter(click => click.id !== id);
};

onMounted(() => {
  window.Telegram.WebApp.expand();

  const index = store.state.ranks.findIndex((rank) => rank.id == userRank.value.id);

  Swiper.use([Navigation, Pagination]);
  swiper.value = new Swiper('.swiper-container', {
    loop: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    initialSlide: index,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    noSwiping: true,
    noSwipingClass: 'swiper-no-swiping',
    on: {
      slideChange: (swiper) => {
        isFirstSlide.value = swiper.isBeginning;
        isLastSlide.value = swiper.isEnd;
      },
    },
  });

  tonConnectUi.onStatusChange(async (wallet) => {
    console.log(wallet);
    await userService.saveWallet(store.state.user.telegramId, wallet.account.address);
  });
});
</script>


<style>
.progress-border {
    background: conic-gradient(#4caf50 0% 25%, transparent 25% 100%);
    border-radius: 50%;
    mask: radial-gradient(farthest-side, transparent calc(100% - 4px), white calc(100% - 4px));
    -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - 4px), white calc(100% - 4px));
}

.progress-border::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid transparent;
    animation: progress 2s linear infinite;
}

.tapped {
  animation: tapAnimation 0.5s ease;
}

@keyframes tapAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 767.98px) {
  .position-absolute.rounded-circle.border {
    width: 12rem;
    height: 12rem;
  }
  img.position-relative {
    width: 10rem;
    height: 10rem;
  }
}

@media (max-width: 575.98px) {
  .position-absolute.rounded-circle.border {
    width: 8rem;
    height: 8rem;
  }
  img.position-relative {
    width: 7rem;
    height: 7rem;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

@keyframes float {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}
</style>
