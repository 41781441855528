import { createStore } from 'vuex'

export default createStore({
  state: {
    user: {},
    missions: [],
    verifyingMission: [],
    linksDone: [],
    ranks: [],
    leaderboard: [],
    claimUpgrades: [],
    mineUpgrades: [],
    tapUpgrades: [],
    dailyRewards: [],
    refClaims: [],
    appIcon: '',
    appRewards: {},
    tapClaimReward: 0,
    luckyCardConfig: {},
    comboCards: {},
    splashImage: '',
    countdown: '00:00:00', // For nextClaim
    comboCountdown: '00:00:00', // For nextComboClaim
    luckyCardCountdown: '00:00:00', // For nextLuckyCardClaim
    dailyClaimCountdown: '00:00:00', // For nextDailyClaim
    countdownInterval: null, // Interval ID for nextClaim countdown
    comboCountdownInterval: null, // Interval ID for nextComboClaim countdown
    luckyCardCountdownInterval: null, // Interval ID for nextLuckyCardClaim countdown
    dailyClaimCountdownInterval: null, // Interval ID for nextDailyClaim countdown
    nextClaim: null, // Timestamp for next claim
    nextComboClaim: null, // Timestamp for next combo claim
    nextLuckyCardClaim: null, // Timestamp for next lucky card claim
    nextDailyClaim: null, // Timestamp for next daily claim
  },
  getters: {
    user: state => state.user,
    missions: state => state.missions,
    verifyingMission: state => state.verifyingMission,
    linksDone: state => state.linksDone,
    ranks: state => state.ranks,
    leaderboard: state => state.leaderboard,
    claimUpgrades: state => state.claimUpgrades,
    mineUpgrades: state => state.mineUpgrades,
    tapUpgrades: state => state.tapUpgrades,
    dailyRewards: state => state.dailyRewards,
    appIcon: state => state.appIcon,
    refClaims: state => state.refClaims,
    appRewards: state => state.appRewards,
    tapClaimReward: state => state.tapClaimReward,
    luckyCardConfig: state => state.luckyCardConfig, // Added getter for luckyCardConfig
    comboCards: state => state.comboCards,
    splashImage: state => state.splashImage,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setMissions(state, missions) {
      state.missions = missions;
    },
    setStorage(state, storage) {
      state.user.storage = storage;
    },
    setBalance(state, balance) {
      state.user.balance = balance;
    },
    addVerifyingMission(state, mission) {
      state.verifyingMission.push(mission);
    },
    removeVerifyingMission(state, mission) {
      state.verifyingMission = state.verifyingMission.filter(m => m !== mission);
    },
    addLinkDone(state, link) {
      state.linksDone.push(link);
    },
    removeLinkDone(state, link) {
      state.linksDone = state.linksDone.filter(l => l !== link);
    },
    setRanks(state, ranks) {
      state.ranks = ranks;
    },
    setLeaderboard(state, leaderboard) {
      state.leaderboard = leaderboard;
    },
    setClaimUpgrades(state, claims) {
      state.claimUpgrades = claims;
    },
    setMineUpgrades(state, upgrades) {
      state.mineUpgrades = upgrades;
    },
    setDailyRewards(state, rewards) {
      state.dailyRewards = rewards;
    },
    setAppIcon(state, appIcon) {
      state.appIcon = appIcon;
    },
    setRefClaims(state, refClaims) {
      state.refClaims = refClaims;
    },
    setAppRewards(state, appRewards) {
      state.appRewards = appRewards;
    },
    setTapUpgrades(state, tapUpgrades) {
      state.tapUpgrades = tapUpgrades;
    },
    setTapClaimReward(state, reward) {
      state.tapClaimReward = reward;
    },
    setLuckyCardConfig(state, config) {
      state.luckyCardConfig = config; // Mutation to set luckyCardConfig
    },
    setComboCards(state, cards) {
      state.comboCards = cards;
    },
    setSplashImage(state, image) {
      state.splashImage = image;
    },
    setCountdown(state, value) {
      state.countdown = value;
    },
    setComboCountdown(state, value) {
      state.comboCountdown = value;
    },
    setLuckyCardCountdown(state, value) {
      state.luckyCardCountdown = value;
    },
    setDailyClaimCountdown(state, value) {
      state.dailyClaimCountdown = value;
    },
    setNextClaim(state, timestamp) {
      state.nextClaim = timestamp;
    },
    setNextComboClaim(state, timestamp) {
      state.nextComboClaim = timestamp;
    },
    setNextLuckyCardClaim(state, timestamp) {
      state.nextLuckyCardClaim = timestamp;
    },
    setNextDailyClaim(state, timestamp) {
      state.nextDailyClaim = timestamp;
    },
    setCountdownInterval(state, intervalId) {
      state.countdownInterval = intervalId;
    },
    setComboCountdownInterval(state, intervalId) {
      state.comboCountdownInterval = intervalId;
    },
    setLuckyCardCountdownInterval(state, intervalId) {
      state.luckyCardCountdownInterval = intervalId;
    },
    setDailyClaimCountdownInterval(state, intervalId) {
      state.dailyClaimCountdownInterval = intervalId;
    },
    clearCountdownInterval(state) {
      if (state.countdownInterval) {
        clearInterval(state.countdownInterval);
        state.countdownInterval = null;
      }
    },
    clearComboCountdownInterval(state) {
      if (state.comboCountdownInterval) {
        clearInterval(state.comboCountdownInterval);
        state.comboCountdownInterval = null;
      }
    },
    clearLuckyCardCountdownInterval(state) {
      if (state.luckyCardCountdownInterval) {
        clearInterval(state.luckyCardCountdownInterval);
        state.luckyCardCountdownInterval = null;
      }
    },
    clearDailyClaimCountdownInterval(state) {
      if (state.dailyClaimCountdownInterval) {
        clearInterval(state.dailyClaimCountdownInterval);
        state.dailyClaimCountdownInterval = null;
      }
    },
  },
  actions: {
    setUser({ commit }, user) {
      commit('setUser', user);
    },
    setMissions({ commit }, missions) {
      commit('setMissions', missions);
    },
    setStorage({ commit }, storage) {
      commit('setStorage', storage);
    },
    setBalance({ commit }, balance) {
      commit('setBalance', balance);
    },
    setNextDailyClaim({ commit }, nextDailyClaim) {
      commit('setNextDailyClaim', nextDailyClaim);
    },
    addVerifyingMission({ commit }, mission) {
      commit('addVerifyingMission', mission);
    },
    removeVerifyingMission({ commit }, mission) {
      commit('removeVerifyingMission', mission);
    },
    addLinkDone({ commit }, link) {
      commit('addLinkDone', link);
    },
    removeLinkDone({ commit }, link) {
      commit('removeLinkDone', link);
    },
    setRanks({ commit }, ranks) {
      commit('setRanks', ranks);
    },
    setLeaderboard({ commit }, leaderboard) {
      commit('setLeaderboard', leaderboard);
    },
    setMineUpgrades({ commit }, upgrades) {
      commit('setMineUpgrades', upgrades);
    },
    setDailyRewards({ commit }, rewards) {
      commit('setDailyRewards', rewards);
    },
    setAppIcon({ commit }, appIcon) {
      commit('setAppIcon', appIcon);
    },
    setRefClaims({ commit }, refClaims) {
      commit('setRefClaims', refClaims);
    },
    setAppRewards({ commit }, appRewards) {
      commit('setAppRewards', appRewards);
    },
    setTapUpgrades({ commit }, tapUpgrades) {
      commit('setTapUpgrades', tapUpgrades);
    },
    setTapClaimReward({ commit }, reward) {
      commit('setTapClaimReward', reward);
    },
    setLuckyCardConfig({ commit }, config) {
      commit('setLuckyCardConfig', config); // Action to commit luckyCardConfig mutation
    },
    setComboCards({ commit }, cards) {
      commit('setComboCards', cards);
    },
    setSplashImage({ commit }, image) {
      commit('setSplashImage', image);
    }
  },
  modules: {}
})
