import { createI18n } from 'vue-i18n'

const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: { 
        en: {
            claimStorage: 'Claim Storage!',
            startMining: 'Start mining now!',
            storage: 'Storage',
            upgrade: 'Upgrade',
            claim: 'Claim',
            collectMin: 'Collect minimum {amount}',
            levelDone: 'You done this level',
            leaderBoard: 'Leader Board',
            upgradesAndBoosts: 'Upgrades & Boosts',
            claimUpgrades: 'Claim Upgrades',
            mineUpgrades: 'Mine Upgrades',
            tapUpgrades: 'Tap Upgrades',
            maxLevel: 'Max Level',
            inviteFriendUpper: 'INVITE A FRIEND',
            inviteFriend: 'Invite a friend',
            refClaimReward: 'Every time your friend claim',
            noFriends: 'No Friends Yet!',
            copyRef: 'Copy Ref',
            missions: 'Missions',
            inviteMission: 'Invite {amount} friends!',
            open: 'Open',
            claimed: 'Claimed!',
            rewards: 'Rewards',
            dailyRewards: 'Daily Reward',
            dailyRewardsDesc: 'Visit daily to claim your rewards',
            day: 'Day {day}',
            dayUpper: 'DAY {day}',
            dailyCombo: 'Daily Combo',
            dailyComboDesc: 'Order randomly to claim the correct combination',
            dailyComboCards: 'Daily Combo Cards',
            play: 'Play',
            luckyCard: 'Lucky Card',
            luckyCardDesc: 'Tap randomly to find your lucky card!',
            attemptsLeft: 'You have {attempts} attempts left.',
            alreadyClaimed: 'You already claimed daily combo reward.',
            completeCombination: 'Complete the combination by swiping the images.',
            tryChance: 'Try Your Chance!',
            alreadyTried: 'You already tried!',
            status: 'Status',
            ok: 'Ok!',
            withdraw: 'Withdraw',
        },
        tr: {
            claimStorage: 'Depolamayı Talep Edin!',
            startMining: 'Madenciliğe şimdi başlayın!',
            storage: 'Depolama',
            upgrade: 'Yükseltme',
            claim: 'Ödülü Al',
            collectMin: 'Minimum {amount} toplayın',
            levelDone: 'Bu seviyeyi tamamladınız',
            leaderBoard: 'Lider Tablosu',
            upgradesAndBoosts: 'Yükseltmeler ve Güçlendirmeler',
            claimUpgrades: 'Yükseltmeleri Talep Edin',
            mineUpgrades: 'Madenci Yükseltmeleri',
            tapUpgrades: 'Yükseltmeler\'e dokunun',
            maxLevel: 'Maksimum Seviye',
            inviteFriendUpper: 'BİR ARKADAŞINIZI DAVET EDİN',
            inviteFriend: 'Bir arkadaşınızı davet edin',
            refClaimReward: 'Her arkadaş davet ettiğinde',
            noFriends: 'Henüz Arkadaş Yok!',
            copyRef: 'Referansı Kopyala',
            missions: 'Görevler',
            inviteMission: '{amount} arkadaş davet edin!',
            open: 'Aç',
            claimed: 'Alındı!',
            rewards: 'Ödüller',
            dailyRewards: 'Günlük Ödül',
            dailyRewardsDesc: 'Ödüllerinizi almak için her gün ziyaret edin',
            day: 'Gün {day}',
            dayUpper: 'GÜN {day}',
            dailyCombo: 'Günlük Kombo',
            dailyComboDesc: 'Doğru kombinasyonu talep etmek için kaydırın',
            dailyComboCards: 'Günlük Kombo Kartları',
            play: 'Oyna',
            luckyCard: 'Şanslı Kart',
            luckyCardDesc: 'Şanslı kartınızı bulmak için rastgele dokunun!',
            attemptsLeft: '{attempts} deneme hakkınız kaldı.',
            alreadyClaimed: 'Günlük kombo ödülünü zaten aldınız.',
            completeCombination: 'Görüntüleri kaydırarak kombinasyonu tamamlayın.',
            tryChance: 'Şansınızı Deneyin!',
            alreadyTried: 'Zaten denediniz!',
            status: 'Durum',
            ok: 'Tamam!',
            withdraw: 'Çekim',
        }
    }
});

export default i18n;