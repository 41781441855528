<template>
    <div class="mt-5 border border-[#3F71D4] bg-[#3F71D4] bg-opacity-10 rounded-2xl p-3 flex items-center justify-between relative overflow-hidden" v-on:click="goLink">
        <div class="flex gap-3 ms-1 items-center justify-start">
            <img :src="mission.platform"  alt="icon" class="h-10 w-10 overflow-visible rounded-full" style="object-fit: contain;">
            <div class="flex flex-col items-start">
                <h4 class="text-white font-semibold text-lg text-start">{{ mission.name }}</h4>
                <div class="flex gap-2 items-center">
                    <h3 class="text-white font-bold">+{{ mission.reward.toLocaleString() }}</h3>
                    <img :src="this.$store.state.appIcon" alt="appicon" class="h-4 w-4 rounded-full"/>
                </div>
            </div>
        </div>

        <button type="button" class="text-white bg-gray-300 font-semibold rounded-full px-7 py-2 mt-2 mb-2 text-center" v-if="isLoading">
            <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </button>
       
        <div v-if="!isMissionDone">
            <button type="button" v-on:click="goToLink" v-if="!isLinkDone && !isLoading" class="text-white bg-gray-400 font-semibold rounded-full px-5 py-2 mt-2 mb-2 text-center" :class="{ 'bg-gray-400': isDone }">
                <h1>{{ $t('open') }}</h1>
            </button>

            <button type="button" v-on:click="claimMission" v-else-if="isLinkDone && !isLoading" class="text-white bg-[#3F71D4] font-semibold rounded-full text-base px-5 py-2 mt-2 mb-2 text-center" :class="{ 'bg-blue-button': isLinkOpened }">
                <h1>{{ $t('claim') }}</h1>
            </button>
        </div>

        <div v-if="isMissionDone" class="flex justify-center">
            <button type="button" v-on:click="goLink" class="text-white bg-gray-400 font-semibold rounded-full text-base px-5 py-2 mt-2 mb-2 text-center" :class="{ 'bg-blue-button': isLinkOpened }">
                <h1>{{ $t('claimed') }}</h1>
            </button>
        </div>
    </div>
</template>

<script>
import UserService from '@/service/userService';

export default {
    name: 'MissionCard',
    props: {
        mission: {
            type: Object,
            required: true
        },
    },
    methods: {
        goLink() {
            if(this.isMissionDone) {
                window.open(this.mission.missionLink, '_blank');
            }
        },

        async goToLink() {
            if (this.isMissionDone) return;

            // Set loading state
            this.$store.commit('addVerifyingMission', this.mission._id);
            
            // Open the link in a new tab
            window.open(this.mission.missionLink, '_blank');

            // Wait for 10 seconds
            await new Promise(resolve => setTimeout(resolve, 10000));
            
            // Set loading state to false and mark the link as done
            this.$store.commit('removeVerifyingMission', this.mission._id);

            this.$store.commit('addLinkDone', this.mission._id);
        },

        async claimMission() {
            if (!this.isLinkDone) return;

            console.log(this.mission);
            await UserService.claimMission(this.$store.state.user.telegramId, this.mission._id);
            const res = await UserService.getUser(this.$store.state.user.telegramId);

            if(res.user) {            
                this.$store.commit('removeLinkDone', this.mission._id);
                this.$store.commit('setUser', res.user);
            }
        }
    },

    computed: {
        user() {
            return this.$store.state.user;
        },

        isMissionDone() {
            return this.user.missionsDone.includes(this.mission._id);
        },
        
        isLinkDone() {
            return this.$store.state.linksDone.includes(this.mission._id);
        },

        isLoading() {
            return this.$store.getters.verifyingMission.includes(this.mission._id);
        },
    }
}
</script>