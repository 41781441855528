const axios = require('axios');
const config = require('../config');

const axiosInstance = axios.create({
    baseURL: config.default.baseUrl,
});

const getUpgrades = async () => {
    const res = await axiosInstance.get('/app/upgrades');
    
    return res.data;
};

const getMissions = async () => {
    const res = await axiosInstance.get('/app/missions');
    
    return res.data;
}

const getRankedLeaderboard = async (id) => {
    const res = await axiosInstance.get(`/app/leaderboard?id=${id}`);

    return res.data;
}

const getDailyClaims = async () => {
    const res = await axiosInstance.get('app/daily-claims');

    return res.data.claims;
}

const getRefClaims = async () => {
    const res = await axiosInstance.get('app/ref-claims');

    return res.data.refClaims;
}

const getAppIcon = async () => {
    const res = await axiosInstance.get('app/icon');

    return res.data.icon.iconURL;
}

const getAppConfigs = async () => {
    const res = await axiosInstance.get('app/app-configs');

    return res.data;
}

const getLuckyCards = async () => {
    const res = await axiosInstance.get('app/lucky-cards');

    return res.data;
}

const getComboCards = async () => {
    const res = await axiosInstance.get('app/combo-cards');

    return res.data;
}

const getSplashImage = async () => {
    const res = await axiosInstance.get('app/splash-image');

    return res.data.imageURL;
}

module.exports = { getUpgrades, getMissions, getRankedLeaderboard, getDailyClaims, getRefClaims, getAppIcon, getAppConfigs, getLuckyCards, getComboCards, getSplashImage };